'use client';

import Grid from '@mui/material/Grid2';
import PageFlowStepper from 'components/PageFlowStepper';

export default function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <Grid container columns={16} height="100%" width="100%">
      <Grid size={{ xs: 1, md: 3, lg: 5 }} />
      <Grid size={{ xs: 14, md: 10, lg: 6 }} display="flex">
        <Grid container height="100%" width="100%">
          <Grid height="fit-content" size={12} width="100%">
            <PageFlowStepper />
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            size={12}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 1, md: 3, lg: 5 }} />
    </Grid>
  );
}
