import { Icon } from '@mui/material';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Stepper, { stepperClasses } from '@mui/material/Stepper';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { useNavigationContext } from 'context/NavigationProvider';

import { useEffect, useMemo, useState } from 'react';

const RocketIcon = (
  <Icon sx={{ width: '60px', height: '27px', paddingLeft: '1.6em', paddingTop: '2px' }}>
    <Image width={48} height={27} alt="rocket" src="/assets/rocket.svg" />
  </Icon>
);

const MoonIcon = (
  <Icon sx={{ width: '24px', height: '29px', paddingRight: '.2em' }}>
    <Image width={28} height={29} alt="moon" src="/assets/moon.svg" />
  </Icon>
);

const StepperStack = styled(Stack)(() => ({
  [`& .${stepperClasses.root} div:nth-of-type(2)`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
  },
}));

const Connector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    border: 0,
    backgroundColor: '#eaeaf0',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#F9BB28',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#F9BB28',
    },
  },
}));

const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  color: '#eaeaf0',
  display: 'flex',
  padding: 0,
  justifyContent: 'center',
  ...(ownerState.active && {
    width: 0,
  }),
}));

function StepIcon(props: StepIconProps, isLast: boolean) {
  const { active, completed, className } = props;
  let icon = <div />;

  if (active) {
    icon = RocketIcon;
  } else if (isLast) {
    icon = MoonIcon;
  }

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </StepIconRoot>
  );
}

type Props = {
  stepModifier?: number;
  numStepModifier?: number;
};

export default function PageFlowStepper({ stepModifier = 0, numStepModifier = 0 }: Props) {
  const { getIndex, getNumSteps } = useNavigationContext();
  const [step, setStep] = useState(-1);
  const [numSteps, setNumSteps] = useState(-1);

  useEffect(() => {
    setStep(getIndex() + stepModifier);
    setNumSteps(getNumSteps() + numStepModifier - 1);
  }, [getIndex, getNumSteps, stepModifier, numStepModifier]);

  const stepArray = useMemo(() => Array.from(Array(numSteps + 1)), [numSteps]);

  if (step >= 0) {
    return (
      <StepperStack width="100%" spacing={0}>
        <Stepper activeStep={step} connector={<Connector />}>
          {stepArray.map((_, idx) => (
            <Step key={idx} sx={{ padding: 0 }}>
              <StepLabel
                sx={{
                  '& .MuiStepLabel-iconContainer': { paddingRight: 0 },
                }}
                StepIconComponent={(props: StepIconProps) =>
                  StepIcon(props, idx >= stepArray.length - 1)
                }
              />
            </Step>
          ))}
        </Stepper>
      </StepperStack>
    );
  } else {
    return <></>;
  }
}
